import { Injectable } from '@angular/core';
import {
  CommonRepositoryAbstract,
  FileUtils,
  MissionFormDataType,
  MissionIntroForm,
  MissionOutroForm,
  SpecificKeys,
} from '@freddy/common';
import * as geofire from 'geofire-common';
import { GuidUtils } from '../../../core/utils/guid.utils';
import { TenantService } from '../../../core/auth/services/tenant.service';
import {
  ArMissionModel,
  Mission,
  MissionTypeEnum,
  SlidingPuzzleMissionModel,
  TranslatedContent,
} from '@freddy/models';
import { AssetService } from '../../../core/assets/asset.service';
import { Locale } from 'locale-enum';
import { Firestore } from '@angular/fire/firestore';
import { catchError, firstValueFrom } from 'rxjs';
import { AssetRepository } from '../../../core/assets/asset.repository';

type IntroOutroKeys = SpecificKeys<MissionFormDataType, 'intro' | 'outro'>;

@Injectable({
  providedIn: 'root',
})
export class MissionRepository extends CommonRepositoryAbstract<Mission> {
  private static readonly DOC_PATH = 'missions';

  constructor(
    private readonly assetService: AssetService,
    private readonly assetRepository: AssetRepository,
    private readonly tenantService: TenantService,
    firestore: Firestore,
  ) {
    super(firestore);
  }

  async createMission(formDataMission: MissionFormDataType): Promise<Mission> {
    try {
      const uploadedAssets = await this.uploadMissionAssets(formDataMission);
      const mission: Mission = {
        ...this.mapFormDataToMission(formDataMission, uploadedAssets),
      };

      return await firstValueFrom(this.create(mission));
    } catch (error) {
      console.error('Failed to create mission', error);
      throw new Error(
        `Failed to create mission: ${error instanceof Error ? error.message : String(error)}`,
      );
    }
  }

  async updateMission(formDataMission: MissionFormDataType): Promise<Mission> {
    try {
      const uploadedAssets = await this.uploadMissionAssets(formDataMission);
      const mission = this.mapFormDataToMission(
        formDataMission,
        uploadedAssets,
      );
      await firstValueFrom(this.update(mission));
      return mission;
    } catch (error) {
      console.error('Failed to update mission', error);
      throw new Error(
        `Failed to update mission: ${error instanceof Error ? error.message : String(error)}`,
      );
    }
  }

  async uploadMissionAssets(
    formDataMission: MissionFormDataType,
  ): Promise<string[]> {
    const uploadedAssets: string[] = [];
    let previousAssets: string[] = [];

    try {
      // Get previous assets if this is an update operation
      if (formDataMission.uid) {
        previousAssets = await this.getPreviousAssets(formDataMission.uid);
      }

      // Upload mission-type specific assets
      await this.uploadMissionTypeSpecificAssets(
        formDataMission,
        uploadedAssets,
      );

      // Upload intro and outro assets
      await this.uploadIntroOutroAssets(formDataMission, uploadedAssets);

      // Only cleanup previous assets after successful upload
      const assetsToDelete = previousAssets.filter(
        (asset) => !uploadedAssets.includes(asset),
      );
      if (assetsToDelete.length > 0) {
        await this.deleteAssets(assetsToDelete);
      }

      return uploadedAssets;
    } catch (error) {
      console.error('Failed to upload mission assets', error);
      throw new Error(
        `Failed to upload mission assets: ${error instanceof Error ? error.message : String(error)}`,
      );
    }
  }

  private async getPreviousAssets(missionUid: string): Promise<string[]> {
    try {
      const previousChallenge = await firstValueFrom(
        this.get(missionUid).pipe(
          catchError((error) => {
            console.warn(`Could not get previous mission: ${error.message}`);
            return [];
          }),
        ),
      );

      return previousChallenge?.assets || [];
    } catch (error) {
      console.warn('Error retrieving previous assets', error);
      return [];
    }
  }

  private async deleteAssets(assetUids: string[]): Promise<void> {
    if (!assetUids.length) return;

    try {
      await Promise.all(
        assetUids.map((assetUid) =>
          this.assetRepository.delete({ uid: assetUid }),
        ),
      );
    } catch (error) {
      console.warn('Error during cleanup of assets', error);
      // Log error but don't fail the operation
    }
  }

  private async uploadMissionTypeSpecificAssets(
    formDataMission: MissionFormDataType,
    uploadedAssets: string[],
  ): Promise<void> {
    const missionType = formDataMission.metadata.missionType;

    switch (missionType) {
      case MissionTypeEnum.AR:
        await this.uploadArMissionAssets(formDataMission, uploadedAssets);
        break;
      case MissionTypeEnum.SLIDING_PUZZLE:
        await this.uploadSlidingPuzzleAssets(formDataMission, uploadedAssets);
        break;
    }
  }

  private async uploadArMissionAssets(
    formDataMission: MissionFormDataType,
    uploadedAssets: string[],
  ): Promise<void> {
    const arMetadata = formDataMission.metadata[MissionTypeEnum.AR];
    if (!arMetadata?.image) return;

    const image = arMetadata.image;
    if (this.isImageNeedsUploading(image)) {
      // Upload new image and add UID to uploadedAssets
      const uploadFile = await this.prepareImageForUpload(
        image,
        'ar_image.png',
      );
      const uploadedPath = await this.assetService.uploadAndCreateAsset(
        uploadFile,
        { type: 'AR' },
      );
      formDataMission.metadata[MissionTypeEnum.AR]!.image = uploadedPath.path;
      uploadedAssets.push(uploadedPath.uid);
    } else {
      // Extract existing UID from path and retain it
      const existingUid = this.getAssetIdFromPath(image);
      if (existingUid) {
        uploadedAssets.push(existingUid);
      }
    }
  }

  private async uploadSlidingPuzzleAssets(
    formDataMission: MissionFormDataType,
    uploadedAssets: string[],
  ): Promise<void> {
    const puzzleMetadata =
      formDataMission.metadata[MissionTypeEnum.SLIDING_PUZZLE];
    if (!puzzleMetadata?.image) return;

    const image = puzzleMetadata.image;
    if (this.isImageNeedsUploading(image)) {
      // Upload new image and add UID to uploadedAssets
      const uploadFile = await this.prepareImageForUpload(
        image,
        'puzzle_image.png',
      );
      const uploadedPath = await this.assetService.uploadAndCreateAsset(
        uploadFile,
        { type: 'PUZZLE' },
      );
      formDataMission.metadata[MissionTypeEnum.SLIDING_PUZZLE]!.image =
        uploadedPath.path;
      uploadedAssets.push(uploadedPath.uid);
    } else {
      // Extract existing UID from path and retain it
      const existingUid = this.getAssetIdFromPath(image);
      if (existingUid) {
        uploadedAssets.push(existingUid);
      }
    }
  }

  private isImageNeedsUploading(imagePath: string): boolean {
    return FileUtils.isBase64Image(imagePath) || imagePath.startsWith('blob:');
  }

  private async prepareImageForUpload(
    imagePath: string,
    fileName: string,
  ): Promise<File> {
    if (FileUtils.isBase64Image(imagePath)) {
      return FileUtils.convertBase64ToFile(imagePath, fileName);
    } else {
      const base64Data = await FileUtils.convertBlobToBase64(imagePath);
      return FileUtils.convertBase64ToFile(base64Data, fileName);
    }
  }

  private async uploadIntroOutroAssets(
    formDataMission: MissionFormDataType,
    uploadedAssets: string[],
  ): Promise<void> {
    const contentTypes: IntroOutroKeys[] = ['intro', 'outro'];

    for (const type of contentTypes) {
      const content = formDataMission[type];

      if (!content) continue;

      for (const locale in content) {
        if (!Object.hasOwn(content, locale)) continue;

        const localeTyped = locale as keyof typeof content;
        const missionContent = content[localeTyped];

        if (!missionContent || !Array.isArray(missionContent.images)) continue;

        for (const file of missionContent.images) {
          const uploadedPath = await this.assetService.uploadAndCreateAsset(
            file,
            { type, locale },
          );
          uploadedAssets.push(uploadedPath.uid);
        }
      }
    }
  }

  async cloneMission(mission: Mission): Promise<Mission> {
    try {
      const newUid = GuidUtils.generateUuid();
      const assetMapping = await this.cloneMissionAssets(mission.assets);

      // Create the base cloned mission with updated asset references
      const clonedMission: Mission = {
        ...mission,
        uid: newUid,
        assets: mission.assets.map(
          (assetId) => assetMapping.get(assetId)?.uid || assetId,
        ),
        updatedAt: new Date(),
      };

      // Update mission-type specific asset references
      this.updateClonedMissionReferences(clonedMission, assetMapping);

      return clonedMission;
    } catch (error) {
      console.error('Failed to clone mission', error);
      throw new Error(
        `Failed to clone mission: ${error instanceof Error ? error.message : String(error)}`,
      );
    }
  }

  private async cloneMissionAssets(
    assetIds: string[],
  ): Promise<Map<string, { uid: string; path: string }>> {
    const assetMapping = new Map<string, { uid: string; path: string }>();

    // Clone all assets and build the mapping
    await Promise.all(
      assetIds.map(async (assetId) => {
        try {
          const clonedAsset = await this.assetService.copyAssetById(assetId);
          assetMapping.set(assetId, {
            uid: clonedAsset.uid,
            path: clonedAsset.path,
          });
        } catch (error) {
          console.warn(`Failed to clone asset ${assetId}`, error);
          // Continue despite individual asset clone failures
        }
      }),
    );

    return assetMapping;
  }

  private updateClonedMissionReferences(
    mission: Mission,
    assetMapping: Map<string, { uid: string; path: string }>,
  ): void {
    if (!mission.metadata) return;

    const missionType = mission.metadata.missionType;

    switch (missionType) {
      case MissionTypeEnum.AR:
        this.updateArMissionReferences(mission, assetMapping);
        break;
      case MissionTypeEnum.SLIDING_PUZZLE:
        this.updateSlidingPuzzleReferences(mission, assetMapping);
        break;
    }
  }

  private updateArMissionReferences(
    mission: Mission,
    assetMapping: Map<string, { uid: string; path: string }>,
  ): void {
    if (!mission.metadata?.[MissionTypeEnum.AR]) return;

    const arModel = mission.metadata[MissionTypeEnum.AR] as ArMissionModel;
    if (!arModel.image) return;

    this.updateAssetReference(arModel, 'image', assetMapping);
  }

  private updateSlidingPuzzleReferences(
    mission: Mission,
    assetMapping: Map<string, { uid: string; path: string }>,
  ): void {
    if (!mission.metadata?.[MissionTypeEnum.SLIDING_PUZZLE]) return;

    const puzzleModel = mission.metadata[
      MissionTypeEnum.SLIDING_PUZZLE
    ] as SlidingPuzzleMissionModel;
    if (!puzzleModel.image) return;

    this.updateAssetReference(puzzleModel, 'image', assetMapping);
  }

  private updateAssetReference(
    model: { [key: string]: any },
    propertyName: string,
    assetMapping: Map<string, { uid: string; path: string }>,
  ): void {
    const assetPath = model[propertyName];
    if (!assetPath) return;

    const assetId = this.getAssetIdFromPath(assetPath);
    if (!assetId) return;

    const newAsset = assetMapping.get(assetId);
    if (newAsset) {
      model[propertyName] = newAsset.path;
    }
  }

  private getAssetIdFromPath(path: string): string | null {
    if (!path) return null;

    // Handle paths like "organizations/public/assets/b761c094-e0ec-4fcb-91ce-3a788daa5086/81a09e5c-b239-40e5-ba7d-ed25ace5176f"
    // Extract the second-to-last segment which should be the asset ID
    const segments = path.split('/');
    // Need at least 2 segments to get the second-to-last one
    return segments.length >= 2 ? segments[segments.length - 2] : null;
  }

  protected getDocPath(): string {
    return (
      this.tenantService.getOrganizationPrefixPath() +
      MissionRepository.DOC_PATH
    );
  }

  private mapFormDataToMission(
    formDataMission: MissionFormDataType,
    uploadedAssets: string[],
  ): Mission {
    const uid = formDataMission.uid ?? GuidUtils.generateUuid();
    const common = this.processCommonData(formDataMission.common);

    return {
      uid,
      assets: uploadedAssets,
      intro: this.removeImagesFromTranslatedContent<MissionIntroForm>(
        formDataMission.intro,
      ),
      outro: this.removeImagesFromTranslatedContent<MissionOutroForm>(
        formDataMission.outro,
      ),
      common,
      metadata: {
        ...formDataMission.metadata,
      },
      updatedAt: new Date(),
    };
  }

  private processCommonData(commonData: any): any {
    if (!commonData) return {};

    const result = { ...commonData };

    if (commonData.location) {
      result.geoHash = geofire.geohashForLocation([
        commonData.location.lon ?? 0,
        commonData.location.lat ?? 0,
      ]);
    }

    return result;
  }

  private removeImagesFromTranslatedContent<T extends { images?: any }>(
    content: TranslatedContent<T>,
  ): TranslatedContent<Omit<T, 'images'>> {
    const result: TranslatedContent<Omit<T, 'images'>> = {};

    if (!content) return result;

    for (const key in content) {
      if (Object.hasOwn(content, key)) {
        const locale = key as Locale;
        const localeContent = content[locale];

        if (localeContent) {
          // Safely extract images from content
          const { images, ...rest } = localeContent;
          result[locale] = rest as Omit<T, 'images'>;
        }
      }
    }

    return result;
  }
}
